<template>
    <div>
        <v-card flat>
            <v-row class="pa-3" align="center" justify="end">
                <v-chip color="red" class="mx-1" small @click="resetFilters()">
                    <v-icon small left>filter_alt_off</v-icon> Clear
                    Filters
                </v-chip>

                <v-chip v-if="!loading" color="blue-grey" class="mx-1" small>
                    <v-icon small left>widgets</v-icon> {{ displayData.length }} Files
                </v-chip>
                <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.mobile">
                    <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined
                        dense rounded clearable></v-text-field>
                </v-col>

            </v-row>

            <v-card-text v-if="!$vuetify.breakpoint.mobile">
                <v-row class="mx-1">

                    <v-col cols="12" sm="2" class="text-center">
                        <b>File No.</b>

                    </v-col>
                    <v-col cols="12" sm="1">
                        <th class="text-center">Carrier <v-badge
                                :content="filters['shippingLineName'] ? filters['shippingLineName'].length : false"
                                :value="filters['shippingLineName'] && filters['shippingLineName'].length > 0"
                                color="primary" offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('shippingLineName')"
                                            :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['shippingLineName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></th>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <b>Vessel <v-badge :content="filters['vesselVoyage'] ? filters['vesselVoyage'].length : false"
                                :value="filters['vesselVoyage'] && filters['vesselVoyage'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('vesselVoyage')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['vesselVoyage']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <b>POL <v-badge :content="filters['portOfLoadValue'] ? filters['portOfLoadValue'].length : false"
                                :value="filters['portOfLoadValue'] && filters['portOfLoadValue'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('portOfLoadValue')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['portOfLoadValue']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <b>Dest. <v-badge
                                :content="filters['finalDestinationValue'] ? filters['finalDestinationValue'].length : false"
                                :value="filters['finalDestinationValue'] && filters['finalDestinationValue'].length > 0"
                                color="primary" offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('finalDestinationValue')"
                                            :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['finalDestinationValue']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></b>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <th class="text-center">Shipper<v-badge
                                :content="filters['shipperName'] ? filters['shipperName'].length : false"
                                :value="filters['shipperName'] && filters['shipperName'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('shipperName')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['shipperName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge></th>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <th class="text-center">Consignee
                            <v-badge :content="filters['consigneeName'] ? filters['consigneeName'].length : false"
                                :value="filters['consigneeName'] && filters['consigneeName'].length > 0" color="primary"
                                offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in headerOptions('consigneeName')" :key="index">
                                            <v-list-item-action class="pr-0">
                                                <v-checkbox v-model="filters['consigneeName']" :value="item.value"
                                                    hide-details dense @change="calculateFilteredResults()"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-badge>
                        </th>
                    </v-col>
                    <v-col cols="12" sm="1" class="text-center">
                        <b>ETD</b>
                        <v-badge :content="filters['etd'] ? filters['etd'].length : false"
                            :value="filters['etd'] && filters['etd'].length > 0" color="primary" offset-y="10">
                            <v-menu offset-y left :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                            small>filter_alt</v-icon></v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item v-for="(item, index) in headerOptions('etd')" :key="index">
                                        <v-list-item-action class="pr-0">
                                            <v-checkbox v-model="filters['etd']" :value="item.value" hide-details dense
                                                @change="calculateFilteredResults()"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.value }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-badge>
                    </v-col>
                    <v-col cols="12" sm="1" class="text-center">
                        <b>ETA</b>

                        <v-badge :content="filters['eta'] ? filters['eta'].length : false"
                            :value="filters['eta'] && filters['eta'].length > 0" color="primary" offset-y="10">
                            <v-menu offset-y left :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon><v-icon
                                            small>filter_alt</v-icon></v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item v-for="(item, index) in headerOptions('eta')" :key="index">
                                        <v-list-item-action class="pr-0">
                                            <v-checkbox v-model="filters['eta']" :value="item.value" hide-details dense
                                                @change="calculateFilteredResults()"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.value }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-badge>

                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
                <div class="mt-1 pt-1">
                    <div class="container" v-if="!loading && displayData.length == 0">
                        <h3 style="color: grey">No Results Found</h3>
                    </div>
                    <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData" height="720px"
                        item-height="30">
                        <template v-slot:default="{ item }">
                            <v-row align="center" style="cursor: pointer">

                                <v-col cols="12" sm="2" class="text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip small style="border:none; font-size: 12px" outlined
                                                @click="viewShipment(item)" v-bind="attrs" v-on="on">
                                                <!-- <v-avatar size="20" left>
                                        {{ item.type.charAt(0).toUpperCase() }}
                                        </v-avatar> -->
                                                <v-icon left v-if="item.status == 'Draft'" small
                                                    color="orange">edit_note</v-icon>
                                                <v-icon left v-else-if="item.status == 'Ready'" small
                                                    color="blue">verified</v-icon>
                                                {{ item.fileNumber }}
                                            </v-chip>
                                        </template>
                                        <span>{{ 'TYPE: ' + item.type }}</span>
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="12" sm="1">
                                    <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined
                                        @click="addToFilter(item.shippingLineName, 'shippingLineName')"
                                        style="border: none; font-size: 11px">
                                        <v-avatar size="24"
                                            :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'"
                                            left>
                                            <v-img v-if="item.shippingLine.logo" :src="item.shippingLine.logo"
                                                contain></v-img>
                                            <h3 v-else style="color: white">{{ item.shippingLineName.charAt(0) }}</h3>
                                        </v-avatar> {{ item.shippingLineName }}
                                    </v-chip>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-chip v-if="item.vesselVoyage" small class="primaryText--text" outlined
                                        @click="addToFilter(item.vesselVoyage, 'vesselVoyage')"
                                        style="border: none; font-size: 11px">
                                        {{ item.vesselVoyage }}
                                    </v-chip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.portOfLoadValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                @click="addToFilter(item.portOfLoadValue, 'portOfLoadValue')">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.portOfLoadValue }}
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.etd)
                                                        }}</span> -->
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.finalDestinationValue">
                                        <template v-slot:activator="{ on }">
                                            <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                @click="addToFilter(item.finalDestinationValue, 'finalDestinationValue')">
                                                <v-avatar size="20" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.finalDestinationValue }}
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.eta)
                                                        }}</span> -->
                                            </v-chip>
                                        </template>
                                        <span style="font-size: 12px">{{ item.finalDestinationCity }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-tooltip top v-if="item.shipper">
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" small class="primaryText--text" outlined
                                                @click="addToFilter(item.shipperName, 'shipperName')"
                                                style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'" left>
                                                    <v-img v-if="item.shipper.logo" :src="item.shipper.logo"
                                                        contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.shipperName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.shipperName }}
                                            </v-chip>
                                        </template>
                                        {{ item.shipper.name }}
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-tooltip top v-if="item.consignee">
                                        <template v-slot:activator="{ on }">
                                            <v-chip small class="primaryText--text" outlined v-on="on"
                                                @click="addToFilter(item.consigneeName, 'consigneeName')"
                                                style="border: none; font-size: 11px">
                                                <v-avatar size="20"
                                                    :color="item.consignee && item.consignee.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.consignee.logo" :src="item.consignee.logo"
                                                        contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.consigneeName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.consigneeName }}
                                            </v-chip>
                                        </template>
                                        {{ item.consignee.name }}
                                    </v-tooltip>

                                </v-col>
                                <v-col cols="12" sm="1" class="text-center">
                                    <v-chip small class="primaryText--text" outlined @click="addToFilter(item.etd, 'etd')"
                                        style="border: none; font-size: 11px">
                                        {{ formatDate(item.etd) }}
                                    </v-chip>
                                </v-col>
                                <v-col cols="12" sm="1" class="text-center">
                                    <v-chip small class="primaryText--text" outlined @click="addToFilter(item.eta, 'eta')"
                                        style="border: none; font-size: 11px">
                                        {{ formatDate(item.eta) }}
                                    </v-chip>
                                </v-col>

                            </v-row>
                            <v-divider class="pt-1"></v-divider>
                        </template>
                    </v-virtual-scroll>
                </div>
            </v-card-text>
            <v-card-text v-if="$vuetify.breakpoint.mobile" class="px-1">
                <v-virtual-scroll
                    v-if="displayData.length > 0"
                    :bench="10"
                    :items="displayData"
                    :height="'calc(100dvh - 56px - 96px)'"
                    :width="'100%'"
                    item-height="410"
                    class="px-1"
                >
                    <template v-slot:default="{ item }">
                        <v-card
                            class="mx-0 my-1"
                            rounded
                            outlined
                            tile
                            height="400"
                        >
                            <v-toolbar
                                dense
                                flat
                                class="mobileToolbarCard"
                                style="background-color: var(--v-greyRaised-base) !important"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            v-on="on"
                                            small
                                            class="primaryText--text"
                                            outlined
                                            style="border: none"
                                        >
                                            <v-avatar :color="item.movementType == 'EXPORT' ? 'deep-orange' : 'blue'" left>
                                                <h4 style="color: white">
                                                    {{ item.movementType.charAt(0) }}
                                                </h4>
                                            </v-avatar>
                                            {{ item.fileNumber }}
                                        </v-chip>
                                    </template>
                                    <span>{{$Format.capitalizeFirstLetter(item.movementType)}}Shipment</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-chip color="danger" small outlined @click="addToFilter(item.status, 'status')" >
                                    <span style="font-size: 12px">
                                        {{$Format.capitalizeFirstLetter(item.status) ?? 'Rejected'}}
                                    </span>
                                </v-chip>
                            </v-toolbar>
                            <v-card-text class="px-2">
                                <v-row>
                                    <v-col
                                        v-for="(header, idx) in mobileHeaders"
                                        :key="idx"
                                        class="pb-0"
                                        cols="6"
                                        sm="6"
                                        lg="6"
                                        style="word-wrap: normal"
                                    >
                                        <span style="display: block; font-size: small">
                                            <b>{{ header.text }}</b>
                                        </span>
                                        <div v-if="header.value == 'type'">
                                                <v-chip small style="border:none; font-size: 12px" outlined>
                                                    <v-icon color="blue" small left v-if="item.type == 'LOGISTICS'">mode_of_travel</v-icon>
                                                    <v-icon color="blue" small left v-else-if="item.type == 'DOCUMENTATION'">article</v-icon>
                                                    <v-icon color="blue" small left v-else-if="item.type == 'HAULAGE'">local_shipping</v-icon>
                                                    <v-icon color="blue" small left v-else-if="item.type == 'MONITORING'">thermostat</v-icon>    
                                                    <span>{{item.type}}</span>
                                                </v-chip>
                                        </div>
                                        <div v-if="header.value == 'shippingLineName'">
                                            <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined
                                                @click="addToFilter(item.shippingLineName, 'shippingLineName')"
                                                style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                                            >
                                                <v-avatar size="16"
                                                :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'"
                                                left
                                                >
                                                    <v-img v-if="item.shippingLine.logo"
                                                        :src="item.shippingLine.logo" contain>
                                                    </v-img>
                                                        <h3 v-else style="color: white">{{ item.shippingLineName.charAt(0) }}</h3>
                                                </v-avatar> 
                                                {{ item.shippingLineName }}
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'vesselVoyage'">
                                            <v-chip v-if="item.vesselVoyage" small class="primaryText--text" outlined
                                                @click="addToFilter(item.vesselVoyage, 'vesselVoyage')" style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;">
                                                {{ item.vesselVoyage }} 
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'portOfLoadValue'">
                                            <v-chip v-if="item.portOfLoadValue" outlined style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"  small
                                                @click="addToFilter(item.portOfLoadValue, 'portOfLoadValue')">
                                                <v-avatar size="16" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.portOfLoadValue }}   
                                                <!-- <span class="ml-2" style="color: #9E9E9E">{{ formatDate(item.etd)
                                                        }}</span> -->
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'finalDestinationValue'">
                                            <v-chip v-if="item.finalDestinationValue" outlined style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"  small
                                                @click="addToFilter(item.finalDestinationValue, 'finalDestinationValue')">
                                                <v-avatar size="16" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar> {{ item.finalDestinationValue }}   
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'shipperName'">
                                            <v-chip v-if="item.shipper"  small class="primaryText--text" outlined
                                                @click="addToFilter(item.shipperName, 'shipperName')" style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;">
                                                <v-avatar size="16"
                                                    :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.shipper.logo" :src="item.shipper.logo"
                                                        contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.shipperName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.shipperName }}
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'consigneeName'">
                                            <v-chip v-if="item.consigneeName && item.consignee" small class="primaryText--text" outlined 
                                                @click="addToFilter(item.consigneeName, 'consigneeName')"
                                                style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;">
                                                <v-avatar size="16"
                                                    :color="item.consignee && item.consignee.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.consignee.logo"
                                                        :src="item.consignee.logo" contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.consigneeName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.consigneeName }}
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'customerName'">
                                            <v-chip v-if="item.customer" small class="primaryText--text" outlined 
                                                @click="addToFilter(item.customerName, 'customerName')"
                                                style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;">
                                                <v-avatar size="16"
                                                    :color="item.customer && item.customer.logo ? 'white' : 'secondary'"
                                                    left>
                                                    <v-img v-if="item.customer.logo"
                                                        :src="item.customer.logo" contain></v-img>
                                                    <h3 v-else style="color: white">{{ item.consigneeName.charAt(0) }}</h3>

                                                </v-avatar> {{ item.customerName }}
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'etd'">
                                            <v-chip small class="primaryText--text" outlined
                                                @click="addToFilter(item.etd, 'etd')"
                                                style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                                            >
                                                {{ formatDate(item.etd) }}
                                            </v-chip>
                                        </div>
                                        <div v-else-if="header.value == 'eta'">
                                            <v-chip small class="primaryText--text" outlined
                                                @click="addToFilter(item.eta, 'eta')"
                                                style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                                            >
                                                {{ formatDate(item.eta) }}
                                            </v-chip>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-btn icon small plain style="position: absolute; right: 5px; bottom: 5px;" @click="viewShipment(item)">
                                <v-icon>open_in_new</v-icon>
                            </v-btn>
                        </v-card>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
        <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
            <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined
            dense rounded clearable></v-text-field>
        </div>
    </div>
</template>
<script>
import dateFormat from 'dateformat'
export default {
    components: {
    },
    data: () => ({
        files: [],
        filters: {},
        filteredResults: [],
        fileQuery: null,
        fileTimeout: null,
        fileHeaders: [
            {
                text: 'File No.',
                value: 'fileNumber'
            },
            {
                text: 'Type',
                value: 'type'
            },
            {
                text: 'Carrier',
                value: 'shippingLineName'
            },
            {
                text: 'Vessel',
                value: 'vesselVoyage'
            },
            {
                text: 'POL',
                value: 'portOfLoadValue'
            },
            {
                text: 'Destination',
                value: 'finalDestinationValue'
            },
            {
                text: 'Shipper',
                value: 'shipperName'
            },
            {
                text: 'Consignee',
                value: 'consigneeName'
            },
            {
                text: 'Customer',
                value: 'customerName'
            },
            {
                text: 'ETD',
                value: 'etd'
            },
            {
                text: 'ETA',
                value: 'eta'
            }
        ],
        headers: [
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Vessel',
                value: 'vesselVoyage'
            },
            {
                text: 'Shipper',
                value: 'shipperName'
            },
            {
                text: 'Consignee',
                value: 'consigneeName'
            },
            {
                text: 'Carrier',
                value: 'shippingLineName'
            }, {
                text: 'POL',
                value: 'portOfLoadValue'
            }, {
                text: 'Destination',
                value: 'finalDestinationValue'
            }, {
                text: 'ETD',
                value: 'etd'
            }, {
                text: 'ETA',
                value: 'eta'
            }
        ],
        loading: false,
        previewShipmentFile: false,
        previewKey: 2000,
        search: null,
        selectedFile: null
    }),
    computed: {
        mobileHeaders() {
            return this.fileHeaders.filter((x) => 
            x.value != "fileNumber"
            );
        },
        displayData() {
            let result = this.filteredResults
            if (this.search) {
                result = result.filter(x =>
                    (x.shipperName && x.shipperName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.fileNumber && x.fileNumber.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadValue && x.portOfLoadValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.portOfLoadCity && x.portOfLoadCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationValue && x.finalDestinationValue.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.finalDestinationCity && x.finalDestinationCity.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.shippingLineName && x.shippingLineName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.vesselVoyage && x.vesselVoyage.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.consigneeName && x.consigneeName.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.eta && x.eta.toLowerCase().includes(this.search.toLowerCase()))
                    || (x.etd && x.etd.toLowerCase().includes(this.search.toLowerCase())))
            }
            return result
        }
    },
    mounted() {
        this.resetFilters()
        this.getFiles()
    },
    methods: {
        addToFilter(value, field) {
            console.log('value', value)
            console.log('field', field)
            if (this.filters[field].includes(value)) {
                this.filters[field] = this.filters[field].filter(x => x != value)
            } else {
                this.filters[field].push(value)
            }
            this.calculateFilteredResults()
        },
        calculateFilteredResults() {
            let result = [...this.files]
            let keys = Object.keys(this.filters)
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                if (this.filters[key] && this.filters[key].length > 0) {
                    result = result.filter(x => this.filters[key].includes(x[key]))
                }
            }
            this.filteredResults = result
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        async getFiles() {
            if (this.fileTimeout) {
                clearTimeout(this.fileTimeout);
            }
            if (this.loading && this.fileQuery) {
                this.fileQuery.abort()
            }
            this.loading = true;
            this.fileTimeout = setTimeout(async () => {
                this.fileQuery = new AbortController();
                const signal = this.fileQuery.signal
                this.files = await this.$API.getRejectedDocumentFiles({
                    // params: this.params,
                    signal
                });
                this.calculateFilteredResults()
                this.loading = false;
            }, 750);
        },
        headerOptions(value) {
            let result = [...new Set(
                this.files.filter(x => x[value])
                    .map(x => ({ name: x[value], value: x[value] })))]
            let uniqueValues = [...new Set(result.map(x => x.value))]
            result = uniqueValues.map(x => (result.find(y => y.value === x)))
            if (value == 'status') {
                result.push({ name: 'Unallocated', value: null })
            }
            return result

        },
        resetFilters() {
            this.filters = {}
            let keys = this.headers.map(x => x.value)
            keys.forEach(x => {
                this.filters[x] = []
            })
            this.calculateFilteredResults()
        },
        async viewShipment(item) {
            this.$router.push({
                path: "/shipment/" + item.shipment.shipmentReference,
            });
        },
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 720px;
    margin: auto;
    /* outline: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomBar {
    height: 3rem;
    width: 100dvw;
    position: fixed;
    display: flex;
    padding: 0.2rem 0.5rem;
    bottom: 0;
    background-color: var(--v-greyRaised-base) !important;
    align-content: center;
}

.mobileToolbarCard :is(.v-toolbar__content) {
    padding: 5px !important;
}
</style>